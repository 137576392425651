import { createModel } from '@rematch/core';
import { deleteCampaignAPI } from 'src/lib/api/campaigns';
import { fetchCalendarCampaignContentAPI, fetchCalendarCampaignsAPI, fetchCalendarFestivalsAPI } from 'src/lib/api/content-calendar';
import { ChannelType } from 'src/lib/constants';
import { RootModel } from 'src/store/models';
import { formatCampaigns, formatFestivals } from '../utils';


export interface Festival {
  id: number;
  start: Date;
  end: Date;
  image: string;
  created_at: Date;
  modified_at: Date;
  title: string;
  description: string;
  window_image: string;
  android_image: string;
  mac_image: string;
  is_global: boolean;
  countries: string[];
  start_date: Date;
  end_date: Date;
  source: string;
  status: string;
}

export interface Campaign {
  id: number;
  title: string;
  description: string;
  redirect_url: string;
  icon: string;
  image: null;
  mobile_image: null;
  images: {
    mac_image: string;
  };
  scheduled_time: number;
  end_campaign_time: null;
  ttl: number;
  labels: any[];
  actions: any[];
  delivered: number;
  ctr: number;
  closed: number;
  name: string;
  clicks: number;
  body_clicks: number;
  cta1_clicks: number;
  cta2_clicks: number;
  smart_delivery: boolean;
  created_at: Date;
  modified_at: Date;
  dispatched_at: Date;
  is_sent: boolean;
  segment_id: number;
  is_scheduled: boolean;
  status: string;
  total_targeted_subscribers: number;
  type: string;
  discount_code: null;
  template_id: null;
  source: string;
  channel: string;
  dispatched_time: Date;
}

export interface ContentCalendarState {
  allFestivals: Festival[];
  allCampaigns: Campaign[];
  isLoading: boolean;
  selectedFestival: Festival | null;
  isPreviewOpen: boolean;
  isContentGenerating: boolean;
}

const initialState: ContentCalendarState = {
  allFestivals: [],
  allCampaigns: [],
  isLoading: true,
  selectedFestival: null,
  isPreviewOpen: false,
  isContentGenerating: false,
};

export const contentCalendar = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setAllCampaigns(state, payload: any[]) {
      return { ...state, allCampaigns: payload };
    },
    setLoading(state, payload: boolean) {
      return { ...state, isLoading: payload };
    },
    setAllFestivals(state, payload: any[]) {
      return { ...state, allFestivals: payload };
    },
    setSelectedFestival(state, payload: any | null) {
      return { ...state, selectedFestival: payload };
    },
    setIsPreviewOpen(state, payload: boolean) {
      return { ...state, isPreviewOpen: payload };
    },
    setIsContentGenerating(state, payload: boolean) {
      return { ...state, isContentGenerating: payload };
    },
  },
  effects: dispatch => ({
    async fetchCalendarData() {
      this.setLoading(true);
      try {
        const [festivalsData, campaignsData] = await Promise.all([
          fetchCalendarFestivalsAPI(),
          fetchCalendarCampaignsAPI(),
        ]);

        if (festivalsData.error) {
          throw new Error(`Error fetching festivals`);
        }
        if (campaignsData.error) {
          throw new Error(`Error fetching campaigns`);
        }

        const formattedFestivals = formatFestivals(festivalsData.data);
        const formattedCampaigns = formatCampaigns(campaignsData.data);

        this.setAllFestivals(formattedFestivals);
        this.setAllCampaigns(formattedCampaigns);
      } catch (error) {
        dispatch.saveToast.showError('error_fetching_calendar_data');
      } finally {
        this.setLoading(false);
      }
    },
    async handleDeleteCampaign(campaignId: number) {
      try {
        const response = await deleteCampaignAPI(campaignId);
        if (response.data && response.data.id === campaignId) {
          this.fetchCampaigns();
          dispatch.saveToast.showDone('Campaign successfully deleted');
        } else {
          dispatch.saveToast.showError('delete_campaign_failed');
        }
      } catch (error) {
        dispatch.saveToast.showError('delete_campaign_failed');
      }
    },
    async clearCampaignDetails(_, rootState: any) {
      const { user, contentCalendar } = rootState;
      const selectedDate = contentCalendar.selectedFestival?.start;

      // Clear notification creator state
      dispatch.notificationCreator.shut();
      dispatch.notificationCreator.setProp({
        prop: 'icon',
        value: user.user.website.company_logo,
      });

      // Update campaign creator state
      dispatch.campaignCreator.shut();
      dispatch.campaignCreator.setProp({
        prop: 'scheduledTime',
        value: selectedDate,
      });
      dispatch.campaignCreator.setProp({
        prop: 'sendType',
        value: 'scheduled',
      });
      dispatch.campaignCreator.setProp({
        prop: 'campaignType',
        value: 'regular',
      });
      dispatch.campaignCreator.setProp({
        prop: 'source',
        value: 'dashboard_content_calendar',
      });
    },
    async fetchCampaigns() {
      try {
        const response = await fetchCalendarCampaignsAPI();
        if (response.error) {
          throw new Error('Error fetching campaigns');
        }
        const formattedCampaigns = formatCampaigns(response.data);
        this.setAllCampaigns(formattedCampaigns);
      } catch (error) {
        dispatch.saveToast.showError('error_fetching_campaigns');
      }
    },
    async handleFestivalClick(payload: { eventInfo: any }, rootState: any) {
      const { user } = rootState;
      this.setIsContentGenerating(true);
      this.setIsPreviewOpen(true);
      this.setSelectedFestival(payload.eventInfo.event);

      try {
        const response = await fetchCalendarCampaignContentAPI(
          payload.eventInfo.event.id,
          ChannelType.WEBPUSH,
        );

        // update notification creator state
        dispatch.notificationCreator.setProp({
          prop: 'title',
          value: response.data.title,
        });
        dispatch.notificationCreator.setProp({
          prop: 'message',
          value: response.data.description,
        });
        dispatch.notificationCreator.setProp({
          prop: 'icon',
          value: user.user.website.company_logo,
        });
        dispatch.notificationCreator.setHeroImage({
          macos: response.data.mac_image,
          mobile: response.data.android_image,
          desktop: response.data.window_image,
        });

        // Update campaign creator state
        dispatch.campaignCreator.setProp({
          prop: 'scheduledTime',
          value: payload.eventInfo.event.start,
        });
        dispatch.campaignCreator.setProp({
          prop: 'sendType',
          value: 'scheduled',
        });
        dispatch.campaignCreator.setProp({
          prop: 'campaignType',
          value: 'regular',
        });
        dispatch.campaignCreator.setProp({
          prop: 'source',
          value: 'dashboard_content_calendar',
        });
      } catch (error) {
        dispatch.saveToast.showError('error_fetching_campaigns');
      } finally {
        this.setIsContentGenerating(false);
      }
    },
  }),
});

export default contentCalendar;